<script setup lang="ts">
import AnnouncementsBarItem from '~/components/home/AnnouncementsBarItem.vue'

const props = defineProps<{ announcements: any }>()

const counter = ref(1)
const hasAnnouncement = ref(true)
const currentAnnouncement = ref(null)

onMounted(() => {
  hasAnnouncement.value = false

  if (!props.announcements || props.announcements.length === 0) return

  currentAnnouncement.value = props.announcements[0]

  setTimeout(() => {
    hasAnnouncement.value = true
  }, 10)
})

const onAnimationEnd = (delay: number) => {
  if (props.announcements.length === 0) return

  currentAnnouncement.value = null
  setTimeout(() => {
    currentAnnouncement.value = props.announcements[counter.value]
    counter.value =
      counter.value === props.announcements.length - 1 ? 0 : counter.value + 1
  }, delay)
}
</script>

<template>
  <Transition>
    <div
      class="bg-s-900 w-full transition-all duration-700 overflow-hidden"
      :class="{
        'border-p-500 h-10 border-t-2 pb-2.5 pt-2': hasAnnouncement,
        'h-0 p-0': !hasAnnouncement,
      }"
    >
      <AnnouncementsBarItem
        :announcement="currentAnnouncement"
        @animation-end="onAnimationEnd"
      />
    </div>
  </Transition>
</template>
