<script setup lang="ts">
const props = defineProps<{ announcement: any }>()
const emit = defineEmits(['animationEnd'])

const containerRef = ref(null)
const borderRef = ref(null)
const titleRef = ref(null)

const hasAnimation = ref(false)
const speed = 0.0175
const distanceToScroll = computed(() => {
  if (!hasAnimation.value) return 0
  const clientWidth = titleRef.value?.clientWidth
  return clientWidth - containerRef.value?.clientWidth * 0.75
})

const animationDuration = computed(() => {
  if (!hasAnimation.value) return 0
  return Math.abs(distanceToScroll.value * speed)
})

const beforeLeave = () => {
  titleRef.value?.style.setProperty('animation', 'none')
  borderRef.value?.classList.remove('announcements-border')
}

const beforeEnter = () => {
  borderRef.value?.classList.add('announcements-border')
}

const timeoutId = ref(null)
onMounted(async () => {})
watch(
  () => props.announcement,
  () => {
    nextTick(() => {
      if (!containerRef.value || !titleRef.value) return
      hasAnimation.value =
        titleRef.value.clientWidth > containerRef.value.clientWidth
      if (timeoutId.value) {
        clearTimeout(timeoutId.value)
      }
      if (hasAnimation.value) return
      timeoutId.value = setTimeout(() => {
        emit('animationEnd', 600)
      }, 8000)
    })
  },
)

const onAnimationEnd = (event) => {
  if (event.animationName.includes('scroll')) {
    emit('animationEnd', 3000)
  }
}

onUnmounted(() => {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value)
  }
})
</script>

<template>
  <NuxtLink
    :to="`/announcements/${announcement?.slug}`"
    data-testid="announcement-bar"
    no-prefetch
  >
    <div
      class="md:max-w-content-container mx-auto flex w-full px-4.5 transition-all duration-1000 md:px-4 lg:px-14 2xl:px-0"
    >
      <div
        ref="borderRef"
        class="announcements-border bg-p-500 mr-1.5 w-0.5 transition-all duration-1000"
      />
      <div
        ref="containerRef"
        class="relative h-5 w-full overflow-hidden pl-[6px] text-white transition-all duration-1000"
      >
        <Transition
          name="announcement-text"
          @before-enter="beforeEnter"
          @before-leave="beforeLeave"
        >
          <span
            v-if="announcement?.title"
            ref="titleRef"
            class="absolute whitespace-nowrap font-semibold"
            :class="{
              'scroll-animation': hasAnimation,
            }"
            :style="{
              animationDuration: `${animationDuration}s`,
              '--scroll-distance': `-${distanceToScroll}px`,
            }"
            @animationend="onAnimationEnd"
          >
            {{ announcement?.title }}
          </span>
        </Transition>
      </div>
    </div>
  </NuxtLink>
</template>

<style scoped>
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(var(--scroll-distance, -100%));
  }
}

@keyframes rotate-border {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.announcements-border {
  animation-name: rotate-border;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

.scroll-animation {
  display: inline-block;
  animation-name: scroll;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-delay: 2s;
}

.announcement-text-enter-active {
  transition: all 1s ease-in-out;
  transform: translateY(0);
}

.announcement-text-leave-active {
  transition: all 1s ease-in-out;
  transform: translateY(-40px);
}

.announcement-text-enter-from {
  transform: translateY(40px);
}

.announcement-text-leave-to {
  transform: translateY(-40px);
}
</style>
